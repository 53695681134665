import React from 'react'
import { motion } from 'framer-motion'
import noLogo from '@Common/Resources/Images/noLogo.webp'
import SiapSmall from '@Common/Resources/Images/LogoSiapSmall.png'
import { Text, Icon, Flex, Hover } from '..'
import { useWindowSize } from 'react-use'
import { useCQuery, getMenuItems, usePageTitle, invalidateQuery, useHandleNavigate } from '@Common/Services'
import state, { doLogout } from '@Common/State'
import { SlidingMenu, HamburgerMenu } from './SlidingMenu'
import TopMenuButtons from './TopMenuButtons'
import NewUpdate from './NewUpdate'
import { ButtonInfo } from './ButtonInfo'

const TopIcon = (props) => <Hover><Icon style={{ marginRight: 30 }} size={26} {...props} /></Hover>

export const TopMenu = () => {
  const { data: { newMessagesBadge = 0, newRequestsBadge = 0, expiringQuotesBadge = 0 } = {} } = useCQuery('badges')
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery('agency')

  const pageTitle = usePageTitle()
  const { width } = useWindowSize()

  const navigate = useHandleNavigate()
  const menuAction = (page) => page === 'logout' ? doLogout() : navigate(page)
  const navigateQuotes = (tab) => () => { invalidateQuery('quotes'); navigate(`/quotes/list?tab=${agency.hasWelfare ? tab : tab - 1}`) }

  const logo = agency?.logo ? state.config.state?.awsBaseURL + agency?.logo : SiapSmall
  const { networkAdmin } = state.auth.state
  const hasQuotes = !networkAdmin && agency?.services?.quotes
  const hasChat = !networkAdmin && agency?.services?.chat

  if (!isSuccessAgency) return null

  return (
    <>
      <SlidingMenu menuAction={menuAction} menuItems={getMenuItems(agency)} />
      <motion.div style={styles.containerStyle} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <Flex js row>
          <HamburgerMenu />
          <Text title bold size='20px' style={{ marginLeft: 20 }} value={pageTitle} />
          <ButtonInfo />
          {!agency.hideNews && <NewUpdate />}
        </Flex>

        <TopMenuButtons menuItems={getMenuItems(agency)} />

        <Flex row>
          {hasQuotes && !agency.hideRequests && <TopIcon name='sheet-new' badge={newRequestsBadge} onClick={navigateQuotes(3)} />}
          {hasQuotes && <TopIcon name='expired' badge={expiringQuotesBadge} onClick={navigateQuotes(2)} />}
          {hasChat && <TopIcon name='chat2' badge={newMessagesBadge} onClick={() => navigate('/messages/chat')} />}

          {width > 1240 && <Text bold title size='20px' style={{ marginRight: 20, whiteSpace: 'nowrap' }} value={networkAdmin ? 'Admin' : agency?.name || ''} />}
          {width > 800 && <img src={logo || noLogo} alt='logoSmall' style={styles.agencyLogo} />}
        </Flex>
      </motion.div>
    </>
  )
}
const styles = {
  containerStyle: {
    width: '100%',
    height: 50,
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 2px 2px rgba(100, 100, 100, 0.5)',
    y: -50,
    opacity: 0
  },
  agencyLogo: {
    maxHeight: 36,
    maxWidth: 36,
    marginRight: 20
  }
}
